<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        @back="hRedirect('apps-targetgroups-list')"
        @save="createItem"
        :title="currentItem.name"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
    >
    </b-card-actions>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.stop.prevent="handleSubmit(createItem)" @reset.prevent="resetForm">
        <b-card>
          <div class="mb-2">
            <b-form-checkbox
                v-model="currentItem.status"
                value="1"
                name="status"
                class="custom-control-success"
            >
              {{ $t('Active') }}
            </b-form-checkbox>
          </div>
          <validation-provider
              #default="validationContext"
              :name="$t('Target Group')"
              rules="required"
          >
            <b-form-group :label="$t('Target Group')">
              <b-form-input
                  id="name"
                  v-model="currentItem.name"
                  trim
                  :placeholder="$t('Target Group')"
              />

              <small class="text-danger">{{ validationContext.errors[0] }}</small>
              <small v-if="validate && Object.keys(validation).includes('name')" class="text-danger"
              >{{ $t(validation.name[0]) }}</small>
            </b-form-group>
          </validation-provider>
        </b-card>
        <b-card>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit">
            {{ $t('Add') }}
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </component>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormDatepicker,
  BFormRadioGroup,
  BFormTimepicker,
  BFormTextarea,
  BFormSelect,
  BFormCheckbox,

} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

import { required, alphaNum, email } from '@validations'
import moment from 'moment'
import BCardActions from '@core/components/b-card-actions/BCardActions'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormDatepicker,
    BFormRadioGroup,
    BFormTimepicker,
    BFormTextarea,
    BFormSelect,
    BFormCheckbox,
    BCardActions,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {

      currentItem: {
        id: '',
        name: '',
        email: '',
        status: 1,
      },
      loaded: true,
      validation: {},
      validate: false,
    }
  },

  methods: {
    createItem() {
      let url = `/targetgroups`
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {}
            this.validate = false

            if (success) {
              this.helperAddItem(`/targetgroups`, this.currentItem, this.$refs.refFormObserver, null, (response) => {
                this.$router.push(
                    {
                      name: 'apps-targetgroups-edit',
                      params: {
                        id: response.data.id
                      },
                    },
                    () => setTimeout(() => {
                      this.hToast('success', 'Success', 'Item saved')
                    })
                )
              })
            } else {
              this.loaded = false
              setTimeout(() => {
                this.loaded = true
              })
            }
          })
    },

    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },
  },
  mounted() {
  },
}
</script>

<style>
</style>
